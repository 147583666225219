<template>
	<div
		class="empty full-height items-center"
	>
		<div class="character3_none pt-130 text-center size-px-16 font-weight-500 color-bbb">{{ text_ }}</div>
	</div>
</template>

<script>
	export default {
		name: 'Empty'
		, props: ['user', 'type', 'text']
		, data: function(){
			return {

			}
		}
		, computed: {
			text_: function(){
				let t = ''
				if(this.text){
					t = this.text
				}else{

					switch (this.type){
						case 'board_config':
							t = '등록된 게시판이 없습니다.'
							break;
						default:
							t = '조회된 내역이 없습니다.'
							break;
					}
				}
				return t
			}
		}
	}
</script>

<style>
	.empty {
		padding: 50px
	}
</style>