<template>
	<div class="flex-1 bg-white pa-24 radius-20 mb-30">

		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
		</div>
		<div class="mt-30">
			<ul
				v-if="plane_list.length > 0"
			>
				<li
					v-for="(plane, p_index) in plane_list"
					:key="'plane_' + p_index"
					class="justify-space-between mb-20 cursor-pointer under-line pb-20"
					@click="toDetail(plane)"
				>
					<div class="img-box-100 mr-14 radius-12 overflow-hidden"><img :src="plane.img_physl_path" @error="$bus.$emit('onErrorImage', $event)" class="width-100"/></div>
					<div class="flex-1 flex-column justify-space-between gap-10 size-px-14 text-left">
						<div class=""><span class="label-plan label-sale">{{ plane.subscrp_plan_state_name }}</span></div>
						<div class="size-px-16 font-weight-700 line-height-140">{{ plane.subscrp_plan_name }}</div>
						<div class="size-px-16 font-weight-600 color-primary flex-row align-center gap-5">
							<div class="flex-row align-center gap-5">
								<img :src="require('@/assets/image/icon_won_blue.svg')" />
								<span class="line-height-1">
									{{ plane.subscrp_amount | makeComma }}원
								</span>
							</div>
							<span class="size-px-13 font-weight-400 color-mafia-blue"> / 월 (VAT별도)</span></div>
					</div>
					<div class="flex-column justify-start">
						<button
						><img :src="require('@/assets/image/icon_setting.svg')" /></button>
					</div>
				</li>
			</ul>
			<Empty
				v-else

				text="구독 게시판을 먼저 생성해주세요"
			></Empty>
		</div>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";
export default {
	name: 'SubscribePlane'
	,
	components: {Empty},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: '구독 플랙 관리'
				, title: '구독 플랜 관리'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, from: 'mafia049'
				, bg_contents: 'bg-gray01'
				, cartel: ''
				, is_side: true

			}
			, item_cartel: {}
			, items_plane: [
				{ subscrp_plan_number: 1, subscrp_plan_name: '루비', subscrp_amount: 5000, is_use: true, img_physl_path: ''}
				, { subscrp_plan_number: 2, subscrp_plan_name: '브론즈', subscrp_amount: 15000, is_use: true, img_physl_path: ''}
				, { subscrp_plan_number: 3, subscrp_plan_name: '실버', subscrp_amount: 25000, is_use: false, img_physl_path: ''}
				, { subscrp_plan_number: 4, subscrp_plan_name: '골드', subscrp_amount: 35000, is_use: true, img_physl_path: ''}
				, { subscrp_plan_number: 5, subscrp_plan_name: '다이아몬드', subscrp_amount: 45000, is_use: true, img_physl_path: ''}
			]
		}
	}
	, computed: {
		plane_list: function(){
			return this.items_plane.filter( (item) => {
				if(item.is_use){
					item.is_use_name = '판매중'
				}else{
					item.is_use_name = '미판매'
				}
				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = this.item_cartel
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPlaneList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_plane_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_plane = result.data.subscrp_plan_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'SubscribePlaneSetting', params: { idx: this.$route.params.idx, p_id: item.subscrp_plan_number}})
		}
		, setMenu: function(item){
			this.item_menu = item
			this.$bus.$emit('to', item.to)
		}
	}
	, async created() {
		await this.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getPlaneList()
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}

</style>